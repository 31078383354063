var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_vm._v(" "+_vm._s(_vm.page_title)+" ")],1)]),_c('div',{staticClass:"box-body"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-type"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.types,"searchable":false,"id":"filter-by-type","deselect-label":"Убрать","label":"name","placeholder":"Тип задачи","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Тип задачи","track-by":"id"},on:{"input":() => {
                             _vm.filter_task.type_id = _vm.type ? _vm.type.id : null;
                             _vm.updateFilters();
                           }},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-car"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.cars,"searchable":true,"id":"filter-by-car","deselect-label":"Убрать","label":"title","placeholder":"Автомобиль","select-label":"Выбрать","selected-label":"Выбрано","title":"Автомобиль","track-by":"id"},on:{"input":() => {
                             _vm.filter_task.car_id = _vm.car ? _vm.car.id : null;
                             _vm.updateFilters();
                           }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" #"+_vm._s(props.option.id)+" "+_vm._s(props.option.title)+" ")]}},{key:"option",fn:function(props){return [_vm._v(" #"+_vm._s(props.option.id)+" "+_vm._s(props.option.title)+" ")]}}]),model:{value:(_vm.car),callback:function ($$v) {_vm.car=$$v},expression:"car"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-client"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.clients,"searchable":true,"show-labels":true,"id":"filter-by-client","deselect-label":"Убрать","label":"title","placeholder":"Клиент","select-label":"Выбрать","selected-label":"Выбрано","title":"Клиент","track-by":"id"},on:{"input":() => {
                             _vm.filter_task.client_id = _vm.client ? _vm.client.id : null;
                             _vm.updateFilters();
                           }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" "+_vm._s(props.option.name)+", "+_vm._s(props.option.phone)+" ")]}}]),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-responsible"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.employees,"searchable":true,"id":"filter-by-responsible","deselect-label":"Убрать","label":"title","placeholder":"Ответственный","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Ответственный","track-by":"id"},on:{"input":() => {
                             _vm.filter_task.responsible_id = _vm.responsible ? _vm.responsible.id : null;
                             _vm.updateFilters();
                           }},model:{value:(_vm.responsible),callback:function ($$v) {_vm.responsible=$$v},expression:"responsible"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1)])])]),_c('card-items',{attrs:{"data_search":_vm.data_search,"filters_query":_vm.filter_task,"relations":_vm.relations,"relations_count":_vm.relations_count,"component":"task-archive-item","item_classes":"col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4","api_resource":"/tasks/archive"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }